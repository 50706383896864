<!--
 * @Author: labels
 * @Date: 2021-01-26 12:45:09
 * @LastEditTime: 2021-02-06 19:10:45
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \04eos-app\src\components\label\label.vue
-->
<template>
  <div class="labels">
    <Button  type="default"  @click="change($i)" v-for="$i in labelData.list" :key="$i.label" :class="on == $i.label? 'on' : ''">
      {{$i.title}}
    </Button>
  </div>
</template>

<script>
  import { Button } from 'vant';
  export default {
    name: 'Label',
    props: {
      labelData: {
        type: Object,
        require: true
      },
      onChange: {
        type: Function,
        require: true
      }
    },
    data() {
      return {
        on: ''
      }
    },
    components: {
      Button
    },
    methods: {
      change(e) {
        this.on = e.label
        this.$emit('onChange', e)
      }
    },
    mounted () { 
      this.on = this.labelData.list[0].label
    }
  }
</script>
<style lang="less" scoped>
  @import './index.less';
</style>