<template>
  <!-- <App :title="title"
       left-arrow
       > -->
  <div class="success-page">
    <Icon name="checked"
          class="success-icon" />
    <div class="describe">{{describe}}</div>
    <Button type="info"
            class="btn"
            @click="clickInfo">{{btnText}}</Button>
  </div>

  <!-- </App> -->
</template>

<script>
import { Icon, Button } from 'vant';
import './index.less'

export default {
  name: 'SuccessPage',
  components: { Icon, Button },
  props: {
    title: {
      type: String,
      default: ''
    },
    describe: {
      type: String,
      default: ''
    },
    btnText: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
    }
  },
  methods: {
    clickInfo () {
      this.$emit('clickInfo')
    }
  }
}
</script>
<style lang="less" scoped>
/deep/ #container {
  padding: 30px;
}
</style>